/*
  Danger! Do not remove any comments from this file, generator will be broken!
 */

export const locale = {
  // __GENERATED_TRANSLATIONS__
  templates: {
     listTitle: 'Templates',
     editFormTitle: 'Update project',
     createFormTitle: 'Create project',
     formFieldsBlockTitle: 'Main',
     formCreateUpdateSubmitErrorMessage: 'Request error',
     menuItemName: 'Templates',
     table: {
       id: 'ID',
       title: 'Name',
       category: 'Template categories',
       actions: 'Action',
     },
     field: {
       name: 'Name*',
       active: 'Status',
     },
    modal: {
      create: 'Create a project template',
      update: 'Update a project template',
      edit: 'Edit template',
      name: 'Template name',
      category: 'Template category',
      createFromProject: 'Create template from project'
    },
    notification: {
      successCreated: 'Template "{title}" successfully created',
      successUpdated: 'Template "{title}" successfully updated',
    },
  },

  userProjects: {
    listTitle: 'User Projects',
    editFormTitle: 'Update project',
    createFormTitle: 'Create project',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'User Projects',
    table: {
      id: 'ID',
      user: 'User',
      email: 'Email',
      createdAt: 'Created',
      title: 'Projects',
      actions: 'Action',
    },
    field: {
      name: 'Name*',
      active: 'Status',
    },
  },
  attachFile: 'Attach file',
  metaDescription: 'Page Drafter is a free tool that helps you create professional website layouts in minutes.',
  metaPageDrafter: 'Page Drafter',
  addModals: 'add',
  builder: {
    created: 'Created',
    pages: 'Pages',
    blocks: 'Blocks',
    noNav: 'Non-Navigation',
    copySuccess: 'Copied to clipboard',
    mobile: 'Mobile',
    desktop: 'Desktop',
    accordion: {
      pageName: 'Page name',
      pageSettings: 'Page settings',
      blockColor: 'Block color',
      noNav: 'Non-navigation page',
      header: 'Header',
      footer: 'Footer',
    },
    select: {
      global: 'Global',
      custom: 'Custom',
      none: 'None',
      blue: 'Blue',
      gray: 'Gray',
      white: 'White',
    },
    comments: {
      editModal: 'Edit comment',
      Comment: 'Comment',
      commentEdited: 'Comment edited'
    },
    modal: {
      pleaseConfirm: 'Please confirm',
      removePage: 'Are you sure you want to delete this page? All details associated to this page will be deleted. This action cannot be undone.',
      selectPanels: 'Select panels',
      pickCategory: 'Pick category',
      pickTemplate: 'Pick a template',
      pickPanel: 'Pick a panel',
      categories: 'Categories',
      panels: 'Panels',
      templates: 'Templates',
      search: 'Search results for: "{value}"',
      found: '{amount} templates found.',
      searchPanels: 'Search results for: "{value}"',
      foundPanels: '{amount} panels found.',
      noResults: 'No results found.',
      tryDiff: 'Try a different search.',
      noPages: 'There are no pages.',
      createPage: 'Create your first page.',
    },
    chooseHeader: 'Choose a header',
    chooseSection: 'Choose a section',
    chooseFooter: 'Choose a footer',
  },
  projects: {
    listTitle: 'My projects',
    folderEdit: 'Folder renamed',
    folderCreate: 'Folder created',
    editFormTitle: 'Update project',
    createFormTitle: 'Create project',
    modalEditProject: 'Edit project',
    formFieldsBlockTitle: 'Main',
    projects: 'projects',
    foldersAmount: 'Folders ({amount})',
    projectsAmount: 'Projects ({amount})',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'My Projects',
    selectTemplate: 'Select a template',
    buildFromScratch: 'Build from scratch',
    name: 'Name',
    created: 'Created',
    table: {
      id: 'ID',
      title: 'Name',
      actions: 'Action',
    },
    field: {
      name: 'Name*',
      active: 'Status',
    },
    emptyList:{
      text1: 'There are no projects.',
      text2: 'Create your first project.'
    },
    modal: {
      useTemplate: 'Use template',
    }
  },
  panels: {
    listTitle: 'Panels',
    editFormTitle: 'Update panel',
    createFormTitle: 'Create panel',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Panels',
    modal: {
      create: 'New template block',
      update: 'Update template block',
      blockName: 'Template block name',
      order: 'Order',
      elementType: 'Element type',
      panelCategories: 'Panel categories',
      usePanel: 'Use panel',
    },
    notification: {
      successCreated: 'Template block "{title}" successfully created',
      successUpdated: 'Template block "{title}" successfully updated',
    },
    elementTypes: {
      header: 'Header',
      section: 'Section',
      footer: 'Footer'
    },
    table: {
      id: 'ID',
      title: 'Name',
      type: 'Element type',
      categoryTitle: 'Panel categories',
      actions: 'Action',
      order: 'Order'
    },
    field: {
      name: 'Name*',
      active: 'Status',
    },
    labels:{
      elementType: 'Element type',
      categories: 'Categories'
    },
    selectTypes:{
      allTypes: 'All types',
      header: 'Header',
      section: 'Section',
      footer: 'Footer'
    },
  },

  panelCategories: {
    listTitle: 'Panel categories',
    editFormTitle: 'Update panel category',
    createModalTitle: 'New panel category',
    updateModalTitle: 'Update panel category',
    createdSuccess: 'Successfully created panel category',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Panel categories',
    table: {
      id: 'ID',
      title: 'Name',
      actions: 'Action',
    },
    field: {
      title: 'Name*',
      name: 'Category name',
      active: 'Status',
    },
  },

  templateCategories: {
    listTitle: 'Template categories',
    editFormTitle: 'Update template category',
    createModalTitle: 'New template category',
    updateModalTitle: 'Update template category',
    createdSuccess: 'Successfully created template category',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Template categories',
    table: {
      id: 'ID',
      title: 'Name',
      actions: 'Action',
    },
    field: {
      title: 'Name*',
      name: 'Category name',
      active: 'Status',
    },
  },
  myProjects: {
    listTitle: 'My Projects',
    selects: {
      dateCreate: {
        days7: 'Last 7 days',
        days30: 'Last 30 days',
        days60: 'Last 60 days',
      },
      sortBy: {
        dateCreated: 'Date created',
        alphabetical: 'Alphabetical'
      }
    },
    labelSelect:{
      dateCreated: 'Date created',
      sortBy: 'Sort by'
    }
  },
  users: {
    listTitle: 'Users',
    editFormTitle: 'Update user',
    createFormTitle: 'Create user',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Users',
    table: {
      id: 'ID',
      name: 'Name',
      email: 'Email',
      createdAt: 'Account created',
      action: 'Action',
    },
    field: {
      fullName: 'Full name',
      name: 'Name*',
      lastName: 'Last name*',
      numberOfPages: 'Number of pages',
      numberOfPagesReq: 'Number of pages*',
      email: 'Email*',
      password: 'Password',
      active: 'Status',
    },
  },
  filters: {
    activity: 'Activity',
    creatingProject: 'Creating a project'
  },
  mobileErrorLayout: {
    welcome: 'Welcome!',
    description: 'Please, use your desktop device or make the browser window wider.'
  },
  main: {
    title: 'Building Platform'
  },
  page: {
    landing: 'Landing Building Platform',
    login: 'Login',
    logout: 'Logout',
    signUp: 'Sign Up',
    resetPassword: 'Reset password',
    dashboard: 'Admin dashboard',
    pageMain: 'Dashboard'
  },
  authLogin: {
    username: 'Email address',
    password: 'Password',
    loginIn: 'Login now',
    forgetPassword: 'Forgot password?',
    welcomeBack: 'Welcome back',
    welcomeBackDesc: 'Welcome back! Login below to view your saved work.',
    newHere: 'You\'re new here?',
    createAnAccount: 'Create a account.',
    rememberMeCheck: 'Remember me',
    invalidCredentials: 'Invalid email or password. Please, try again',
    modal: {
      title: 'Oops!',
      inOrder: 'In order to access the platform, it\'s necessary to validate your email. ',
      afterSignup: 'After signing up, an email containing a confirmation link was dispatched to your email address.',
      wouldYou: 'Would you like me to re-send the email for you?',
      btn: 'Send again'
    }
  },
  authSignUp: {
    fullName: 'Full name',
    email: 'Email address',
    password: 'Password',
    minLength: '8',
    confirmPassword: 'Confirm password',
    alreadyHave: 'Already have an account?',
    loginIn: 'Login now.',
    createAcc: 'Create an account',
    createMyAcc: 'Create my account',
    createAccDesc: 'Sign up for a free account to download your designs, build multiple pages, and create additional projects.',
    modalBtn: '',
    modal: {
      title: 'Thank you for registration!',
      descSent: 'We have sent a confirmation of registration to',
      descCheck: 'Please check your inbox as well as your spam folder if you do not find the email in your main inbox.',
      descReceive: 'Didn\'t receive the email?',
      descSendAgain: 'Click here to send again.',
      btn: 'Go to email',
      btnSendAgain: 'Send again',
      titleResend: 'Didn\'t receive the email?',
      descSentResend: 'Make sure the email is correct or write another one.',
    },
    errorAlreadyUsed: 'This user is already registered'
  },
  authRestore: {
    restoreMyPass: 'Reset my password',
    noWorries: 'No worries, we’ll send you reset instructions.',
    email: 'Email address',
    resetPass: 'Reset password',
    backToLogin: 'Back to login',
    okEmail: 'We just emailed you a link to reset your password.',
  },
  authRecoveryPassword: {
    resetMyPassword: 'Reset my password',
    afterReset: 'Password reset',
    newPass: 'Your new password must be different to previously used passwords.',
    newPassSuccess: 'Your password has been successfully reset. Click below to log in magically.',
    reset: 'Reset password',
    continue: 'Continue',
    first: 'Password',
    second: 'Password',
    errorToken: 'Password recovery link is invalid',
  },
  accountSettings: {
    accountSettings: 'Account settings',
    myDetailsTab: 'My details',
    passwordTab: 'Password',
    myDetails: {
      title: 'Basic info',
      description: 'Update your personal information here',
      btnUpdateAvatar: 'Update',
      btnDeleteAvatar: 'Delete',
      successUpdate: 'Account settings successfully updated',
      form: {
        fullName: 'Full name',
        emailAddress: 'Email address',
        signupMethod: 'Signup method',
        accountCreated: 'Account Created',
        lastLogin: 'Last Login',
      },
    },
    password: {
      title: 'Account details',
      description: 'This is how you access your account',
      form: {
        oldPassword: 'Old password',
        newPassword: 'New  password',
        confirmPassword: 'Confirm password',
      },
      btnUpdate: 'Update',
      btnUpdatePassword: 'Update password',
      btnClear: 'Clear',
      successPassChanged: 'Password changed successfully',
    },
    modal: {
      title: 'Delete Image?',
      desc: 'Are you sure you want to delete this image? You cannot undo this action.',
      cancel: 'Cancel',
      delete: 'Delete',
      errorRequest: 'Request error',
    }
  },
  welcomePopup: {
    popup1: {
      title: 'Header Section',
      description: 'Click this box to select and add a header.'
    },
    popup2: {
      title: 'Body Sections',
      description: 'Click this box to select and add body sections.'
    },
    popup3: {
      title: 'Footer Sections',
      description: 'Click this box to select and add footer.'
    },
    popup4: {
      title: 'New Page',
      description: 'Click here to add a new page.'
    },
    popup5: {
      title: 'Restart Demo',
      description: 'Click here any time to restart this demonstration.'
    },
    popup7: {
      title: 'Preview Page',
      description: 'Click here to view a fullscreen preview of your\n' +
        'design.'
    },
    popup8: {
      title: 'Fullscreen',
      description: 'Click here to go to fullscreen mode.'
    },
    popup10: {
      title: 'Undo',
      description: 'Click here to undo the previous action.'
    },
    popup11: {
      title: 'Redo',
      description: 'Click here to redo the previous action.'
    },
    popup13: {
      title: 'Download PDF',
      description: 'Click here to download the mockup in PDF format.'
    },
    popup14: {
      title: 'Delete Page',
      description: 'Click here to delete this page.'
    },
    popup15: {
      title: 'Save as template',
      description: 'Click here to save this project as template.'
    },
    popup16: {
      title: 'Viewing Project Layers',
      description: 'Click here to see the project layers. You can also move the panel.'
    },
  },
  dashboard: {
    main: 'Dashboard',
    applications: 'Applications'
  },
  homePage: {
    listTitle: 'Home',
    header: {
      nav: {
        features: 'Features',
        benefits: 'Benefits',
      },
      menu: {
        login: 'Login',
        signup: 'Sign up',
      }
    },
    chip: 'Pagedrafter: Website Mockup Builder',
    main: {
      usual: 'Create',
      highlighted: 'Professional Website Mockups ',
      nextUsual: 'Without Design Skills'
    },
    additional: 'Pagedrafter is an easy-to-use website mockup builder that lets you create mockups for any type of website. ',
    search: {
      placeholder: 'Enter your email',
      btn: 'Start free'
    },
    link: {
      alternatively: 'Alternatively, start with ',
      template: 'a template',
      or: 'or',
      scratch: 'from scratch'
    },
    features: {
      websiteMockupBuilder: {
        title: 'Website Mockup Builder ',
        titleColor: 'for Everyone',
        additional: 'Pagedrafter is a free website mockup builder that anyone can use, regardless of their design skills. With a simple drag-and-drop interface, you can easily create a mockup that reflects your vision.',
        itemOne: 'Swift and easy mockup creation',
        itemTwo: 'Integrated Communication',
        itemThree: 'Extensive Component Library',
      },
      easyWay: {
        title: ' to Create Website Mockups',
        titleColor: 'The Easy Way',
        additional: 'With Pagedrafter\'s intuitive drag-and-drop interface, you can quickly and easily create stunning website mockups in minutes.',
        itemOne: 'Smooth learning experience',
        itemTwo: 'Craft your website design swiftly',
        itemThree: '100% free with comprehensive feature accessibility',
      },
      transformIdeas: {
        title: 'into Stunning Website Mockups',
        titleColor: 'Transform Your Ideas ',
        additional: 'Effortlessly create captivating website mockups with Pagedrafter\'s extensive library of meticulously crafted website components for stunning designs.',
        itemOne: 'Cross-Platform Responsive Design Compatibility',
        itemOneSecondary: 'Designs adapt effortlessly, delivering consistently engaging experiences on various platforms.',
        ItemTwo: 'Frequent Updates for Fresh Design',
        itemTwoSecondary: 'Stay ahead as we enhance our platform, providing the latest tools for modern and captivating designs.',
      },
    },
    benefits: {
      chip: '',
      title: '',
      benefits: {
        unlimitedCreativity: {
          title: 'Unlimited Creativity',
          additional: 'Convenient interface for seamless website design in minutes.',
        },
        designFlexibility: {
          title: 'Design Flexibility',
          additional: 'Customize layouts with dark, light, and grey alternatives for individuality.',
        },
        organizationalExcellence: {
          title: 'Organizational Excellence',
          additional: 'Effortlessly organize pages into a navigational structure for comprehensive design.',
        },
        swiftPreview: {
          title: 'Swift Preview',
          additional: 'Instantly visualize your creations with real-time preview.',
        },
        platformAdaptive: {
          title: 'Platform-Adaptive Design',
          additional: 'Ensure responsive design for various devices and platforms.',
        },
        continuousUpdates: {
          title: 'Continuous Updates',
          additional: 'Regular updates and new features for the freshness of your design.',
        },
      }
    },
    footer: {
      nav: {
        features: 'Features',
        benefits: 'Benefits',
      },
      socials: {
        instagram: 'Instagram',
        facebook: 'Facebook',
      },
      copyright: 'Made by Pagedrafter  Copyright © '
    },
  },
  builderNotes: {
    title: 'Notes',
    labelTextArea: 'Type your note/message',
    modalTitle: 'Attach files',
    modalLinc: 'Click to upload',
    modalText: 'or drag and drop',
    modalFiles: 'PDF, DOC, DOCX, XLS, XLSX or PPT',
    modalFilesImage: 'JPG, PNG',
    noteText: 'Add any useful notes here and don\'t forget to attach any relevant files.'
  },
  errorPage: {
    title: 'Oh, hello there!',
    additional: 'You\'ve landed on a page that no longer exists, but don\'t worry,\n' +
      '          we\'ll help get you to the right place.',
    btn: 'Back Home'
  },
  base: {
    save: 'Save',
    submit: 'Submit',
    saveChanges: 'Save changes',
    saveSuccess: 'Saved successfully',
    removeSuccess: 'Removed successfully',
    cancel: 'Cancel',
    remove: 'Remove',
    rename: 'Rename',
    renameFolder: 'Rename Folder',
    moveFolder: 'Move to folder',
    downloadPreview: 'Upload project image',
    delete: 'Delete',
    create: 'Create',
    createNew: 'Create new',
    confirm: 'Confirm',
    areYouSureToDelete: 'Are you sure want to delete?',
    areYouSureToDeactivate: 'Confirm deactivation',
    areYouSureToActivate: 'Confirm activation',
    yes: 'Yes',
    no: 'No',
    add: 'Add New',
    edit: 'Edit',
    editing: 'Editing',
    time: 'Time',
    back: 'Back',
    filters: 'Filters',
    apply: 'Apply',
    selectAll: 'Select all',
    clearAll: 'Clear all',
    deactivate: 'Deactivate',
    activate: 'Activate',
    listStatusActive: 'Active',
    listStatusInactive: 'Inactive',
    search: 'Search',
    noData: 'No data',
    all: 'All',
    allCount: 'All ({count})',
    onlyChosen: 'Only chosen',
    attention: 'Attention',
    fill: 'Fill',
    validationError: 'Validation error',
    validationErrorServer: 'validation server error',
    noMoreItems: 'No more items',
    noElementsFound: 'No elements found',
    import: 'Import',
    exportTemplate: 'Export template',
    resetFilters: 'Reset',
    createdAt: 'Created at',
    createdBy: 'Created by',
    actions: 'Actions',
    city: 'City',
    district: 'District',
    region: 'Region',
    country: 'Country',
    today: 'Today',
    yesterday: 'Yesterday',
    currentMonth: 'Current month',
    noDataAvailable: 'No data available',
    startTypingText: 'Start typing text',
    change: 'Change',
    month: 'Month',
    day: 'Day',
    hour: 'Hour',
    minute: 'Minute',
    toTheBegining: 'To the beginning',
    warning: 'Warning',
    choosePeriod: 'Choose period',
    selectPeriod: 'Select period',
    rangeDays: 'Range days',
    filtersActive: 'Active',
    filtersDeactivated: 'Deactivated',
    filtersAll: 'All',
    selectFields: 'Select fields',
    open: 'Hide',
    close: 'Show',
    close2: 'Close',
    reset: 'Reset',
    operator: 'Operator',
    notOperator: 'Not operator',
    export: 'Export',
    exportReport: 'Export report',
    manualRequest: 'Manual request',
    apiAutoRequests: 'API auto requests:',
    images: 'Images',
    product: 'Product',
    selected: 'Selected',
    items: 'items',
    onPage: 'Showing',
    onThePage: 'On the page',
    paginationOnPage: 'Showing',
    paginationFrom: 'from',
    history: 'History',
    field: 'Field',
    success: 'Success',
    comments: 'Comments',
    choose: 'Choose',
    value: 'Value',
    role: 'Role',
    group: 'Group',
    niche: 'Niche',
    total: 'Total',
    from: 'from',
    outOf: 'out of',
    mobileView: 'For correct display use desktop version',
    goToMain: 'Go to main',
    returnToMainPage: 'Return to main page',
    showNumber: 'Show number',
    failedToLoad: 'Failed to load',
    failedToLoadDescription: 'Error while loading page. Try reload page',
    limitText: 'and {count} more',
    addSelectedOptions: 'Add selected options',
    removeSelectedOptions: 'Remove selected options',
    ok: 'Хорошо',
    selectDate: 'Select date',
    newFolder: 'New folder',
    folderName: 'Folder name',
    getStarted: 'Let\'s get started!',
    projectName: 'Project name',
    folder: 'Folder',
    buildFromScratch: 'Build from scratch',
    selectTemplate: 'Select a template',
    projectTemplates: 'Project templates',
    presets: {
      today: 'Today',
      todayAndYesterday: 'Today and yesterday',
      yesterday: 'Yesterday',
      currentWeek: 'Current week',
      previousWeek: 'Previous week',
      currentMonth: 'Current month',
      previousMonth: 'Previous month',
      currentYear: 'Current year',
    },
    beWaiting: 'Wait',
    dataLoading: 'The data is being processed',
    asidePlatformManagement: 'Platform management',
    asideUsersManagement: 'Users management',
    asideReports: 'Reports',
  },
  error: {
    invalid: '{name} is invalid',
    required: '{name} required',
    requiredIf: '{name} required',
    email: 'Wrong Email format',
    maxLength: 'Max length - {length} symbols',
    minLength: 'Min length - {length} symbols',
    maxValue: 'Max value: {value}',
    minValue: 'Min value: {value}',
    onlyNumbers: 'Only numbers',
    valueUsed: 'This value is already used',
    onlyIntegerNumbers: 'Only integer numbers',
    onlyIntegerPositiveNumbers: 'Only integer positive numbers',
    onlyWords: 'Only words',
    allow: 'Your password should contain: ',
    sameField: 'Passwords should be the same',
    regexPassword: 'Wrong password format',
    url: 'Wrong URL',
    dateFromLess: 'Date "from" should be less than date "to"',
    dateToLess: 'Date "to" should be greater than date "from"',
    commaSpaceRule: 'Start and end of field shouldn\'t contain space or comma',
    lengthDemand: '8 symbols at least',
    capitalDemand: 'One capital letter',
    lowercaseDemand: 'One lowercase letter',
    numberDemand: 'One digit (0-9)',
    numbersDemand: 'At least one digit (0-9)',
    lettersDemand: 'At least one letter (a-z)',
    symbolDemand: 'One symbol (!@#%&*.)',
    latinDemand: 'Password contains only latin letters',
    invalidValue: 'Wrong value',
    notBeEmpty: 'Value shouldn\'t be empty',
    error403Title: '403!',
    error403Desc: 'Page doesn\'t exists or you don\'t have access.',
    error404Title: '404!',
    error404Desc: 'Something seems to have gone wrong! The page you are requesting does not exist. Maybe it is outdated or deleted.',
    emailExist: 'This email is already registered',
  },
  uploadArea: {
    upload: 'Click to upload',
    moveYourFileOr: 'or drag and drop',
    allowed: 'PDF, DOC, DOCX, XLS, XLSX, PPT, PNG, JPEG, max size: 60MB',
    invalidType: 'Wrong file format',
    maxSize: 'File size exceeds acceptable limit'
  }
}

export default locale
